import { call, select } from 'redux-saga/effects';

import { BEAT_TYPE, TIME_EVENT_TYPE } from 'constant/EventConst';
import { ECG_CHART_UNIT } from 'constant/ChartEditConst';

import ApiManager from 'network/ApiManager/ApiManager';

import {
  selectBeatsNEctopicList,
  selectSelectionStrip,
} from 'redux/duck/testResultDuck';

import { PostAfibCommand } from './commandPattern/eventReview_PostAfib';
import { PostPauseCommand } from './commandPattern/eventReview_PostPause';
import { PostOthersCommand } from './commandPattern/eventReview_PostOthers';
import { PostAVBlockCommand } from './commandPattern/eventReview_PostAVBlock';

import { eventUpdateInst } from '../eventUpdateCmdPattern';

const PAUSE_MIN_DURATION_WAVEFORM_LENGTH = 500; // 500 waveformIndexes = 2 Sec
const CONST_TIME_EVENT_TYPE = TIME_EVENT_TYPE;

/**
 * # function list
 *  * export function* preProcessTimeEventEdit
 *  * function* preProcessForPause
 *  * export function getBeatInfosFromSelectionMarker
 *  * export function getRRIBeatsArray
 *  * function createPreProcessError
 */

/**
 * TimeEvent 요청 전 선택된 항목에 대한 전처리를 하는 함수.
 * @param {TimeEventsPostRequestBodyType} requestBody
 */
export function* preProcessTimeEventEdit({ updateReqOption }) {
  const updateEventCommandMap = {
    [CONST_TIME_EVENT_TYPE.AF]: PostAfibCommand,
    [CONST_TIME_EVENT_TYPE.PAUSE]: PostPauseCommand,
    [CONST_TIME_EVENT_TYPE.OTHERS]: PostOthersCommand,
    //
    [CONST_TIME_EVENT_TYPE.AVB_2]: PostAVBlockCommand,
    [CONST_TIME_EVENT_TYPE.AVB_3]: PostAVBlockCommand,
  };

  const eventUpdateCommandInst = new updateEventCommandMap[
    updateReqOption.timeEventType
  ]({
    updateReqOption,
  });

  yield eventUpdateInst.execute(eventUpdateCommandInst);

  switch (updateReqOption.timeEventType) {
    case CONST_TIME_EVENT_TYPE.PAUSE:
      return yield call(preProcessForPause, updateReqOption);
    case CONST_TIME_EVENT_TYPE.AF:
    case CONST_TIME_EVENT_TYPE.OTHERS:
    case CONST_TIME_EVENT_TYPE.AVB_2:
    case CONST_TIME_EVENT_TYPE.AVB_3:
      return [call(ApiManager.postTimeEvent, updateReqOption)];
    default:
      return;
  }
}

/**
 * eventType이 PAUSE일 때의 전처리 함수
 */
function* preProcessForPause({
  tid,
  eventType,
  onsetWaveformIndex,
  terminationWaveformIndex,
  isRemove,
}) {
  const beatsNEctopicList = yield select(selectBeatsNEctopicList);
  const { onset, termination } = yield select(selectSelectionStrip);
  const removeCase = {
    selectedEventMarker: 'selectedEventMarker',
    selectedSection: 'selectedSection',
  };

  // event marker를 선택 후 삭제(기존 구간을 onset으로만 선택하여 remove할때, termination이 없는 경우를 처리)
  const removeCaseResult = _getRemoveCase(termination);
  if (isRemove && removeCaseResult === removeCase.selectedEventMarker) {
    return [
      call(ApiManager.postTimeEvent, {
        isRemove,
        tid,
        eventType,
        onsetWaveformIndexes: [onsetWaveformIndex],
        terminationWaveformIndexes: [terminationWaveformIndex],
      }),
    ];
  }

  // onset이 beatsNEctopicList에 존재하는지 여부
  const isOnsetInList = beatsNEctopicList[onset.representativeWaveformIndex];
  // termination이 beatsNEctopicList에 존재하는지 여부
  const isTerminationInList =
    beatsNEctopicList[termination.representativeWaveformIndex];
  if (!isOnsetInList || !isTerminationInList) {
    throw createPreProcessError('CannotSelectBetweenJump');
  }

  const selectedBeatsInfo = getBeatInfosFromSelectionMarker({
    onsetWaveformIndex,
    terminationWaveformIndex,
    beatsNEctopicList,
  });

  // 철회의 경우 전체구간을 1번만 전송해도 삭제할 수 있으므로 1번의 API를 요청한다
  if (isRemove && removeCaseResult === removeCase.selectedSection) {
    return [
      call(ApiManager.postTimeEvent, {
        isRemove,
        tid,
        eventType,
        onsetWaveformIndexes: [selectedBeatsInfo.waveformIndexList.at(0)],
        terminationWaveformIndexes: [
          selectedBeatsInfo.waveformIndexList.at(-1),
        ],
      }),
    ];
  }

  // PAUSE 설정의 경우
  const rRIBeatsArray = getRRIBeatsArray(selectedBeatsInfo);
  return [
    call(ApiManager.postTimeEvent, {
      ...rRIBeatsArray,
      isRemove,
      tid,
      eventType,
    }),
  ];

  function _getRemoveCase(termination) {
    if (termination && !termination.clickedWaveformIndex) {
      return removeCase.selectedEventMarker;
    }

    return removeCase.selectedSection;
  }
}

export function getBeatInfosFromSelectionMarker({
  onsetWaveformIndex,
  terminationWaveformIndex,
  beatsNEctopicList,
}) {
  // 비트 정보를 받아올 longTermStrip의 index를 구한다.
  const startStripIndex = parseInt(
    onsetWaveformIndex / ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
  );
  const lastStripIndex = parseInt(
    terminationWaveformIndex / ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
  );

  let beatInfo = {
    waveformIndexList: [], // 반환할 R peek의 waveformIndex의 배열
    beatTypeList: [], // 반환할 R beat의 type 배열
  };
  for (let i = startStripIndex; i <= lastStripIndex; i++) {
    const representativeWaveformIndex = String(
      i * ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX
    );

    if (!beatsNEctopicList[representativeWaveformIndex]) {
      throw createPreProcessError('CannotSelectBetweenJump');
    }

    const beatsNEctopic = beatsNEctopicList[representativeWaveformIndex].beats;
    // onset Selection Marker의 안쪽 인접 beat의 위치로 범위를 좁힌 후 beatsNEctopic.waveformIndex배열 객체의 인덱스를 구한다.
    const indexOfTargetEditOnsetWaveformIndex =
      beatsNEctopic.waveformIndex.findIndex((v) => v >= onsetWaveformIndex);
    // termination Selection Marker의 안쪽 인접 beat의 위치로 범위를 좁힌 후 beatsNEctopic.waveformIndex배열 객체의 인덱스를 구한다.
    const indexOfTargetEditTerminationWaveformIndex =
      beatsNEctopic.waveformIndex.findLastIndex(
        (v) => v <= terminationWaveformIndex
      );

    // 구한 범위 안쪽에 존재하는 비트의 값들을 가져온다.
    const waveformIndexList = beatsNEctopic.waveformIndex.slice(
      indexOfTargetEditOnsetWaveformIndex,
      indexOfTargetEditTerminationWaveformIndex + 1
    );
    const beatTypeList = beatsNEctopic.beatType.slice(
      indexOfTargetEditOnsetWaveformIndex,
      indexOfTargetEditTerminationWaveformIndex + 1
    );

    beatInfo = {
      waveformIndexList: [...beatInfo.waveformIndexList, ...waveformIndexList],
      beatTypeList: [...beatInfo.beatTypeList, ...beatTypeList],
    };
  }

  return beatInfo;
}

/**
 * beat의 배열을 받아 R-R interval 구간으로 만들어 반환하는 함수
 * 23.11.27 - time-event create&remove 초기 버전 용
 */
export function getRRIBeatsArrayOldVersion({
  waveformIndexList,
  beatTypeList,
}) {
  if (waveformIndexList.length < 2) {
    throw createPreProcessError('DOES NOT EXISTS RRI');
  }

  const RRIBeatsArray = [];
  for (let i = 1; i < waveformIndexList.length; i++) {
    const isNoiseEventCurrentIndex = beatTypeList[i] === BEAT_TYPE.NOISE;
    const isNoiseEventPreviousIndex = beatTypeList[i - 1] === BEAT_TYPE.NOISE;
    const isOver2SecSection = false;
    // const isOver2SecSection =
    //   waveformIndexList[i] - waveformIndexList[i - 1] <
    //   PAUSE_MIN_DURATION_WAVEFORM_LENGTH;
    if (
      isNoiseEventCurrentIndex ||
      isNoiseEventPreviousIndex ||
      isOver2SecSection
    ) {
      continue;
    }

    RRIBeatsArray.push({
      onsetWaveformIndex: waveformIndexList[i - 1],
      terminationWaveformIndex: waveformIndexList[i],
    });
  }

  return RRIBeatsArray;
}
/**
 * beat의 배열을 받아 R-R interval 구간으로 만들어 반환하는 함수
 * 23.11.27 - time-event create&remove 개선 된 버전
 *  - 개선 사항: req시 구간을 멀티로 요청 할 수 있음, res에는 생성된 event marker 받음
 */
export function getRRIBeatsArray({ waveformIndexList, beatTypeList }) {
  if (waveformIndexList.length < 2) {
    throw createPreProcessError('DOES NOT EXISTS RRI');
  }

  const RRIBeatsArray = {
    onsetWaveformIndexes: [],
    terminationWaveformIndexes: [],
  };
  for (let i = 1; i < waveformIndexList.length; i++) {
    const isNoiseEventCurrentIndex = beatTypeList[i] === BEAT_TYPE.NOISE;
    const isNoiseEventPreviousIndex = beatTypeList[i - 1] === BEAT_TYPE.NOISE;
    const isOver2SecSection =
      waveformIndexList[i] - waveformIndexList[i - 1] <
      PAUSE_MIN_DURATION_WAVEFORM_LENGTH;
    if (
      isNoiseEventCurrentIndex ||
      isNoiseEventPreviousIndex ||
      isOver2SecSection
    ) {
      continue;
    }

    RRIBeatsArray.onsetWaveformIndexes.push(waveformIndexList[i - 1]);
    RRIBeatsArray.terminationWaveformIndexes.push(waveformIndexList[i]);
  }

  return RRIBeatsArray;
}

function createPreProcessError(message) {
  const error = new Error();
  error.name = 'preProcessEditedTimeEvent Error';
  error.message = message;
  return error;
}

// type
/**
 * @typedef {keyof TIME_EVENT_TYPE} BeatType
 *
 * @typedef BeatsNEctopicData BeatsNEctopic의 data 객체
 * @property {Date} createAt
 * @property {WaveformIndex[]} onsetWaveformIndex
 * @property {WaveformIndex[]} terminationWaveformIndex
 * @property {CONST_TIME_EVENT_TYPE} beatType
 * @property {number} hrAvg
 * @property {any[]} noises
 * @property {any[]} ectopics
 */

/**
 * Selection Marker 범위 내에 존재하는 R peek의 정보를 반환하는 함수
 * @param {{onsetWaveformIndex: WaveformIndex, terminationWaveformIndex: WaveformIndex, beatsNEctopicList: any}} props
 * @return {{waveformIndex: WaveformIndex[], beatType: BeatType[]}}
 */
